import { DocumentType, ModelType } from '@innedit/innedit-type';
import dayjs from 'dayjs';
import { diff } from 'deep-object-diff';
import { navigate } from 'gatsby';
import { Form } from 'packages/formidable';
import { ModelData } from 'packages/innedit';
import React, {
  ReactElement,
  SyntheticEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { change } from 'redux-form';

import { MenuType } from '~/components/Actions';
import { ActionProps } from '~/components/Actions/props';

import HeaderActions from './HeaderActions';

const useDocId = (docId?: string) => {
  const ref = useRef<string>();

  useEffect(() => {
    ref.current = docId;
  });

  return ref.current;
};

export interface FormProps<T extends ModelType> {
  className?: string;
  closeOnClick?: (event: SyntheticEvent<HTMLButtonElement>) => void;
  collectionName?: string;
  createOnClick?: (document: DocumentType<T>) => void;
  deleteOnClick?: () => void;
  deleteConfirm?: string;
  deleteError?: string;
  displayClose?: boolean;
  displayRemove?: boolean;
  docId?: string;
  forceUnregisterOnUnmount?: boolean;
  id?: string;
  keepDirtyOnReinitialize?: boolean;
  menu?: MenuType;
  model: ModelData<T>;
  name: string;
  onSubmit?: (values: Partial<T>, oldValues?: Partial<T>) => void;
  params?: { [key: string]: any };
  subMenu?: (ActionProps | ActionProps[])[];
  type: 'create' | 'update';
}

const CMSForm = <T extends ModelType>({
  className,
  closeOnClick,
  createOnClick,
  deleteOnClick,
  deleteConfirm,
  deleteError,
  displayClose = true,
  displayRemove = true,
  docId,
  forceUnregisterOnUnmount,
  id,
  keepDirtyOnReinitialize,
  menu,
  model,
  name,
  onSubmit,
  params,
  subMenu,
  type,
}: FormProps<T>): ReactElement => {
  const [initialValues, setInitialValues] = useState<Partial<T>>();
  const [doc, setDoc] = useState<DocumentType<T>>();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const previousDocId = useDocId(docId);

  useEffect(() => {
    let unsub: () => void;

    if (docId) {
      if (previousDocId !== docId) {
        setInitialValues(undefined);
      }
      unsub = model.watchById(docId, snapshot => {
        setDoc(snapshot);
      });
    }

    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [docId]);

  useEffect(() => {
    if ('create' === type) {
      setInitialValues(model.initialize());
    } else if (doc) {
      // le document a été mis à jour
      if (initialValues) {
        const newDiffValues: Partial<T> = diff(
          initialValues,
          model.extractAttributes(doc, initialValues),
        );
        // On change uniquement les valeurs qui ont été mises à jour
        if (newDiffValues && Object.keys(newDiffValues).length > 0) {
          Object.keys(newDiffValues).forEach(key => {
            dispatch(change(name, key, doc[key as keyof T], false));
          });
          // il faut réinitialiser le formulaire
          setInitialValues(model.extractAttributes(doc));
        }
      } else {
        setInitialValues(model.extractAttributes(doc));
      }
    }
  }, [doc, type]);

  const deleteDoc = async () => {
    if (doc && doc.id) {
      const confirmation = window.confirm(t(deleteConfirm || 'deleteConfirm'));

      if (confirmation) {
        const date = dayjs().toISOString();
        await toast.promise(
          model.update(doc.id, {
            deleted: true,
            deletedAt: date,
            updatedAt: date,
          } as Partial<T>),
          {
            error: 'Problème lors de la suppression',
            pending: 'Suppression en cours',
            success: 'Suppression réussie',
          },
        );
      }
    } else {
      await toast(`${t(deleteError || 'deleteError')} : no item id`);
    }
  };

  const handleCloseOnClick = async (
    event: SyntheticEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();

    if (!doc || !doc.id) {
      await deleteDoc();
    }

    if (closeOnClick) {
      closeOnClick(event);
    } else {
      await navigate(-1);
    }
  };

  const handleDeleteOnClick = async (
    event: SyntheticEvent<HTMLButtonElement>,
  ): Promise<void> => {
    event.preventDefault();

    await deleteDoc();

    if (deleteOnClick) {
      deleteOnClick();
    } else {
      navigate(-1);
    }
  };

  const handleOnSubmit = async (values: Partial<T>): Promise<void> => {
    document.documentElement.scrollTo(0, 0);
    let data;

    if (!doc || !doc.id) {
      // le document n'est pas il faut le créer

      data = await toast.promise(model.create(values), {
        error: 'Problème lors de la création',
        pending: 'Création en cours',
        success: 'Création réussie',
      });

      if (data && createOnClick) {
        await createOnClick(data);
      }
    } else {
      await toast.promise(model.set(doc.id, values), {
        error: "Problème lors de l'enregistrement",
        pending: 'Enregistrement en cours',
        success: 'Enregistrement réussi',
      });
    }

    if (onSubmit) {
      onSubmit(values, doc);
    }

    if (data) {
      // c'est une création
      // TODO il faut faire la redirection vers la bonne page
      // await navigate(`${itemPathnamePrefix}${data.id}/update/`, {
      //   replace: true,
      // });
    } else {
      // setInitialValues(undefined);
    }
  };

  return (
    <div className={className}>
      <HeaderActions
        closeOnClick={handleCloseOnClick}
        deleteOnClick={displayRemove ? handleDeleteOnClick : undefined}
        displayClose={displayClose}
        doc={doc}
        formName={name}
        menu={menu}
        model={model}
        subMenu={subMenu}
      />
      <Form
        className="mt-6"
        datas={model.getDatas()}
        destroyOnUnmount={false}
        enableReinitialize
        footerProps={{ className: 'my-6' }}
        forceUnregisterOnUnmount={forceUnregisterOnUnmount}
        hideSubmitButton
        id={id || `view-form__${name}`}
        initialValues={initialValues || model.initialize()}
        keepDirtyOnReinitialize={keepDirtyOnReinitialize}
        name={name}
        onSubmit={handleOnSubmit}
        params={{
          ...params,
          docId,
          collectionName: model.collectionName,
          [name]: doc,
        }}
      />
    </div>
  );
};

export default CMSForm;
