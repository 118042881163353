import { DocumentType, ModelType } from '@innedit/innedit-type';
import { diff } from 'deep-object-diff';
import compact from 'lodash/compact';
import { ModelData } from 'packages/innedit';
import React, { FC, SyntheticEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { reset, submit } from 'redux-form';

import { MenuType } from '~/components/Actions';
import { ActionProps } from '~/components/Actions/props';
import Header from '~/components/Header';
import IconChevronLeft from '~/icons/ChevronLeft';
import IconDelete from '~/icons/Delete';

interface HeaderActionsProps<T extends ModelType> {
  closeOnClick?: (event: SyntheticEvent<HTMLButtonElement>) => void;
  deleteOnClick?: (event: SyntheticEvent<HTMLButtonElement>) => void;
  displayClose?: boolean;
  doc?: DocumentType<T>;
  formName: string;
  menu?: MenuType;
  model: ModelData<T>;
  subMenu?: (ActionProps | ActionProps[])[];
}

const HeaderActions: FC<HeaderActionsProps<any>> = ({
  closeOnClick,
  deleteOnClick,
  displayClose,

  doc,
  formName,
  menu,
  model,
  subMenu,
}) => {
  const dispatch = useDispatch();
  const [pristine, { form }] = useSelector((globalState: any) => [
    0 ===
      Object.keys(
        diff(
          globalState.form[formName]?.initial,
          globalState.form[formName]?.values,
        ),
      ).length,
    globalState,
  ]);

  const title =
    model.labelFields &&
    model.labelFields.length > 0 &&
    form[formName] &&
    form[formName].values
      ? compact(
          model.labelFields.map(field => form[formName].values[field]),
        ).join(' - ')
      : undefined;

  const handleCancelOnClick = () => {
    dispatch(reset(formName));
  };

  const handleOnClick = () => {
    dispatch(submit(formName));
  };

  const newMenu = {
    left: pristine && menu?.left ? [...menu.left] : [],
    right: pristine && menu?.right ? [...menu.right] : [],
  };

  if (displayClose && closeOnClick) {
    newMenu.left.push({
      icon: IconChevronLeft,
      label: 'Fermer',
      onClick: closeOnClick,
    });
  }

  if (pristine && doc) {
    if (deleteOnClick) {
      newMenu.right.push([
        {
          icon: IconDelete,
          label: 'Supprimer',
          onClick: deleteOnClick,
        },
      ]);
    }
  }

  if (!pristine) {
    newMenu.right.push({
      label: 'Annuler',
      onClick: handleCancelOnClick,
    });
    newMenu.right.push({
      label: doc?.id ? 'Enregistrer' : 'Créer',
      onClick: handleOnClick,
    });
  }

  return (
    <>
      <Header
        menu={{
          left: newMenu.left,
          right: newMenu.right,
        }}
        subMenu={subMenu}
        title={title}
      />
    </>
  );
};

export default HeaderActions;
